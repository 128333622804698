

//Default Bereich, nur allgemeine Einstellungen
//#########################################################################################

html {
  font-size: 15px; /*Fallback*/
}

body{
  //0 - 720px
  @include media-breakpoint-between(xs5,xs){
    font-size: 15px;
  }
  //720-1500
  @include media-breakpoint-between(xs,lg){
    font-size: 16px;
  }
  //1500-2200
  @include media-breakpoint-between(lg,xl){
    font-size: 21px;
  }
  //ab 2200
  @include media-breakpoint-up(xl){
    font-size: calc(0.5vw + 1.0vh);
  }
}

h1{
  margin-bottom: .3rem;
  /*clear: both;*/

  //0 - 720px
  @include media-breakpoint-between(xs5,xs){
    font-size: 21px;
  }
  //720-1500
  @include media-breakpoint-between(xs,lg){
    font-size: 23px;
  }
  //1500-2200
  @include media-breakpoint-between(lg,xl){
    font-size: 30px;
  }
  //ab 2200
  @include media-breakpoint-up(xl){
    font-size: calc(1.1rem + .6vw);
  }
}

h2,h3,h4,h5,h6{
  margin-bottom: .7rem;
  /*clear: both;*/

  //0 - 720px
  @include media-breakpoint-between(xs5,xs){
    font-size: 18px;
  }
  //720-1500
  @include media-breakpoint-between(xs,lg){
    font-size: 20px;
  }
  //1500-2200
  @include media-breakpoint-between(lg,xl){
    font-size: 26px;
  }
  //ab 2200
  @include media-breakpoint-up(xl){
    font-size: calc(1.0rem + .7vw);
    font-weight: 600;
  }
}