.newslist {
    margin-top: 1.8em;

    .news {
        .news-content {
            @include media-breakpoint-up(xs) {
                display: grid;
                grid-area: news;
            }
            @include media-breakpoint-down(xs) {
                display: block;
            }

            @include media-breakpoint-down(xs) {

            }
            @include media-breakpoint-up(xs) {
                grid-template-columns: 1fr 4fr;
                grid-template-areas: "image teaser";
            }
        }


        .news-date {
            font-size: 0.7em;
            color: #656565;
            margin-top: 0.5em;
        }

        .news-image {
            picture {
                //margin-top: 0.5em;
            }

            display: grid;
            grid-area: image;

            img {
                width: 100%;
                @include media-breakpoint-up(xs) {
                    padding-right: 0.5em;
                    margin-top: 0.4em;
                }
            }
        }

        .news-teaser {
            display: grid;
            grid-area: teaser;
            padding-right: 0.5em;
        }

        .news-author {
            color: #535353;
            float: left;
            font-size: 0.7em;
            padding-right: 0.5em;
        }

        .news-tags {
            @include media-breakpoint-down(xs) {
                float: left;
                clear: both;
            }
            float: right;

            .badge {
                @include media-breakpoint-down(xs) {
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                }
                background-color: $colorHeader;
            }
        }

        .news-headline {
            clear: both;
        }
    }
}
