.layout-main-right{
  display: grid;

  @include media-breakpoint-up(xs) {
    grid-template-columns: 1fr 35%;
    grid-template-areas: "main right";
  }

  .main {
    padding-top: 1em;
    padding-bottom: 1em;
    display: grid;
    @include media-breakpoint-up(md) {
      grid-area: main;
    }
  }

  .right {
    display: grid;
    align-content: start;
    @include media-breakpoint-up(xs) {
      grid-area: right;
      background-color: #f7f7f7;
    }
  }
}

.layout-main-right-right{
  display: grid;

  @include media-breakpoint-up(xs) {
    grid-template-columns: 1fr 35%;
    grid-template-areas: "main right";
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 22% 13%;
    grid-template-areas: "main right rightright";
    //grid-template-rows:;
    gap: 1px 1px;
  }

  .main {
    display: grid;
    @include media-breakpoint-up(md) {
      grid-area: main;
    }
  }

  .right {
    display: grid;
    align-content: start;
    @include media-breakpoint-up(xs) {
      grid-area: right;
      background-color: #f7f7f7;
    }
  }
  .rightright {
    display: grid;
    align-content: start;
    @include media-breakpoint-up(md) {
      grid-area: rightright;
      margin-right: .5em;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}