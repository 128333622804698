.search-result-wrapper {
  position: absolute;
  background-color: white;
  z-index: 10000;
  width: 100%;
  left: 0;
  color: $colorHeadline;

  .search-result {
    border: 1px solid gray;
    padding: 1em;
    .search-result-item {
      a {
        display: grid;
        grid-template-columns: 32px 1fr;
        grid-template-areas: "logo content";
        text-decoration: none;
      }
      img {
        padding-right: 0.3em;
        display: grid;
        grid-area: logo;
      }

      h3{
        display: grid;
        grid-area: content;
      }
    }

    @include media-breakpoint-between(xs5, xs) {
      h2, h3, h4 {
        font-size: 1.0em;
      }
    }
    @include media-breakpoint-up(xs) {
      h2, h3, h4 {
        font-size: 1.2em;
      }
    }
  }
}

.search-filter-wrapper {
  .search-filter-content {
    @include media-breakpoint-down(xs) {
      margin-left: 0.2em;
    }
    @include media-breakpoint-up(xs) {
      margin-left: 1em;
    }
  }

  position: fixed;
  @include media-breakpoint-down(xs) {
    top: 3.5em;
  }
  @include media-breakpoint-up(xs) {
    top: 2.9em;
  }
  background-color: white;
  width: 100%;

  border-bottom: 1px solid $colorHeader;
  border-top: 1px solid $colorHeader;

  .search-filter {
    color: mediumvioletred;
  }
}