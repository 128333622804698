footer {
    background-color: $colorHeader;
    color: white;
    margin-top: 2.5em;
    padding: 0.5em;

    a, a:visited, a:hover {
        color: lightgrey;
    }

    .logo{
        height: 60px;
        @include media-breakpoint-up(md) {
            height: 80px;
        }
    }

    #copyright {
        ul {
            display: flex;
            align-items: center;
            list-style-type: none;
            li{
                padding-right: 1em;
            }
        }
    }

    #extra{
        ul {
            display: flex;
            align-items: center;
            list-style-type: none;
            li{
                padding-right: 1em;
            }
        }
    }
}
