picture.aspect_16_9 {
  display: block;
  position: relative;
  padding-top: 56.25%;
}

picture.aspect_3_2 {
  display: block;
  position: relative;
  padding-top: 66.66%;
}

picture.aspect_4_3 {
  display: block;
  position: relative;
  padding-top: 75%;
}



img.aspect_16_9 {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
  object-position: left;
  //object-position: 102% 89%;
}
img.aspect_3_2 {
  aspect-ratio: 3/2;
  width: 100%;
  object-fit: cover;
  object-position: left;
}
img.aspect_4_3 {
  aspect-ratio: 4/3;
  width: 100%;
  object-fit: cover;
  object-position: left;
}
