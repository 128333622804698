#pagezoom_wrapper {
  right: 10px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

#pagezoom_wrapper_rel {
  display: flex;
  flex-wrap: wrap;
}

#pagezoom_small {
  font-size: 13px;
  width: 15px;
  height: 15px;
}
.pagezoom_item {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-right: 2px;
  text-align: center;
  vertical-align: text-bottom;
  font-size: 16px;
  line-height: 1.27em;
  cursor: pointer;
  background: #d7ded7;
  color: #000;
  padding-top: 1px;
}
#pagezoom_medium {
  font-size: 16px;
  width: 19px;
  height: 19px;
}
#pagezoom_large {
  font-size: 20px;
  width: 23px;
  height: 23px;
}
body.pagezoom_large #pagezoom_large,
body.pagezoom_small #pagezoom_small,
body.pagezoom_medium #pagezoom_medium,
.pagezoom_item.active,{
  background-color: #fdb913;
}

body.pagezoom_small {
  @include media-breakpoint-down(xs){
    font-size: 1.0rem;
  }
  @include media-breakpoint-up(xs){
    font-size: 1.0rem;
  }
}

body.pagezoom_medium {
  @include media-breakpoint-down(xs){
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(xs){
    font-size: 1.3rem;
  }
}

body.pagezoom_large {
  font-size: 1.6rem;
}