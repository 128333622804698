.progress-container {
  position: fixed; //sticky
  width: 100%;
  height: 8px;
  background: #3f3f3f;
  top: 0;
  z-index: 1021; //sticky-top bootstrap 4.0 hat z-index: 1020;

  .progress-bar {
    height: 8px;
    background: #31609c;
    width: 0%;
  }
}
@media (min-width:1023px) {
  .progress-container {
    top:0;
  }
}
